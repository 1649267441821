import { AxiosInstance } from 'axios';
import { orderBy } from 'lodash';
import format from 'string-format';

import {
  CommunityQuestTag,
  FilterType,
  SingleCommunityType,
} from '@/lib/features/community/types';
import { RankingItemType } from '@/lib/features/types';
import { urlfy } from '@/lib/helpers/urlfy';
import { Endpoint } from '@/lib/infra/api/constants';

import { filters } from '../utils';

export async function getAllCommunities(api: AxiosInstance) {
  return await api
    .get<{
      total: number;
      results: SingleCommunityType[];
    }>(Endpoint.GET_ALL_COMMUNITIES)
    .then(
      (res) => res.data,
      (err) => {
        console.log(err);
        return {
          total: 0,
          results: [] as SingleCommunityType[],
        };
      }, // TODO: falling back to empty array.
    );
}

export async function getCommunity(api: AxiosInstance, slug: string) {
  return api
    .get<SingleCommunityType>(format(Endpoint.GET_COMMUNITY, { slug }))
    .then((res) => res.data as SingleCommunityType);
}

export async function getCommunityQuestTags(
  api: AxiosInstance,
  communityId: string,
) {
  const searchParams = new URLSearchParams([
    ['excludeTemplateTags', 'true'],
    ['userAvailableQuests', 'true'],
  ]);
  const baseUrl = format(Endpoint.GET_COMMUNITY_QUEST_TAGS, {
    id: communityId,
  });

  const url = `${baseUrl}${
    searchParams.size ? `?${searchParams.toString()}` : ``
  }`;

  return api
    .get<{
      tags: CommunityQuestTag[];
    }>(url)
    .then((res) => res.data);
}

export async function getCommunityXP(api: AxiosInstance, communityId: string) {
  return await api
    .get(
      format(Endpoint.GET_COMMUNITY_RANKING_XP, {
        id: communityId,
      }),
    )
    .then((res) => res.data as RankingItemType);
}

export async function getJoinedCommunities(api: AxiosInstance) {
  return api
    .get(
      Endpoint.GET_ALL_COMMUNITIES +
        urlfy({
          onlyJoined: 'true',
        }),
    )
    .then(
      (res) =>
        res.data as {
          results: SingleCommunityType[];
          total: number;
        },
    );
}

export function getTotalCommunityQuests(
  results: SingleCommunityType[],
): number {
  let totalQuests = 0;

  for (let i = 0; i < results.length; i++)
    totalQuests += results[i].activeQuests;

  return totalQuests;
}

export type PostJoinCommunityPayload = {
  communityId: string;
  referrerHandle?: string;
};
export function postJoinCommunity(
  api: AxiosInstance,
  { communityId, referrerHandle }: PostJoinCommunityPayload,
) {
  const url = format(Endpoint.JOIN_COMMUNITY, { id: communityId });
  const pathname = referrerHandle ? `?referrerHandle=${referrerHandle}` : ``;

  return api.post(url + pathname).then((res) => res.data);
}

export function postLeaveCommunity(api: AxiosInstance, id: string) {
  return api
    .post(format(Endpoint.LEAVE_COMMUNITY, { id }))
    .then((res) => res.data);
}

export async function getSortedCommunities(
  api: AxiosInstance,
  filterType: FilterType,
) {
  const data = await getAllCommunities(api);
  const sortedResult = orderBy(data.results, filters(filterType), 'desc');

  return {
    total: data.total,
    results: sortedResult,
  };
}
