import { AxiosInstance } from 'axios';

import { CookieKeys } from '@/lib/features/auth/types';

export class ApiBase {
  private static instance: ApiBase;
  private api: AxiosInstance | null;
  private tokens: CookieKeys | null;

  private constructor() {
    this.api = null;
    this.tokens = null;
  }

  public static getInstance(): ApiBase {
    if (!ApiBase.instance) {
      ApiBase.instance = new ApiBase();
    }

    return ApiBase.instance;
  }

  public getApi(): AxiosInstance {
    if (!this.api) {
      throw new Error('API instance not set');
    }
    return this.api;
  }

  public setApi(api: AxiosInstance): void {
    this.api = api;
  }

  public getTokens(): CookieKeys {
    if (!this.tokens) {
      throw new Error('Tokens not set');
    }
    return this.tokens;
  }

  public setTokens(tokens: CookieKeys): void {
    this.tokens = tokens;
  }
}
