import { AxiosInstance } from 'axios';
import format from 'string-format';

import { LeaderboardListItemType } from '@/lib/features/community/types';
import { Endpoint } from '@/lib/infra/api/constants';

export function getLeaderBoard(
  api: AxiosInstance,
  page: number,
  limit: number,
  resourceTypeId: string,
) {
  return api
    .get<{
      users: LeaderboardListItemType[];
    }>(
      format(Endpoint.GET_LEADERBOARD, { resourceTypeId }) +
        `?page=${page}&limit=${limit}`,
    )
    .then((res) => res.data)
    .then((res) => res.users);
}

export function getCommunityLeaderBoard(
  api: AxiosInstance,
  page: number,
  limit: number,
  communityId: string,
  resourceTypeId: string,
) {
  return api
    .get<{
      users: LeaderboardListItemType[];
      total: number;
      offset: number;
    }>(
      format(Endpoint.GET_COMMUNITY_LEADERBOARD, {
        communityId,
        resourceTypeId,
      }) + `?page=${page}&limit=${limit}`,
    )
    .then((res) => res.data);
}

export function getUserRank(
  api: AxiosInstance,
  {
    communityId,
    resourceTypeId,
  }: {
    communityId?: string;
    resourceTypeId: string;
  },
) {
  return api
    .get<{}>(format(Endpoint.GET_USER_RANK, { communityId, resourceTypeId }))
    .then((res) => res.data);
}
