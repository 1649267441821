import { QUEST_STATUS } from '@/lib/features/quests/types';

export function getStatus(t: (key: string) => string) {
  return function (status?: QUEST_STATUS) {
    switch (status) {
      case QUEST_STATUS.RESOLVING:
        return t('quests:status.resolving');
      case QUEST_STATUS.ACTIVE:
        return t('quests:status.active');
      case QUEST_STATUS.ABANDONED:
        return t('quests:status.abandoned');
      case QUEST_STATUS.COMPLETED:
        return t('quests:status.completed');
      case QUEST_STATUS.FAILED:
        return t('quests:status.failed');

      default:
        return t('quests:status.notStarted');
    }
  };
}

export function getGamingStatus(t: (key: string) => string) {
  return function (status?: QUEST_STATUS) {
    switch (status) {
      case QUEST_STATUS.RESOLVING:
        return t('quests:gamingStatus.resolving');
      case QUEST_STATUS.ACTIVE:
        return t('quests:gamingStatus.active');
      case QUEST_STATUS.ABANDONED:
        return t('quests:gamingStatus.abandoned');
      case QUEST_STATUS.COMPLETED:
        return t('quests:gamingStatus.completed');
      case QUEST_STATUS.FAILED:
        return t('quests:gamingStatus.failed');

      default:
        return t('quests:gamingStatus.notStarted');
    }
  };
}
