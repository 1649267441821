import groupBy from 'lodash-es/groupBy';
import isFinite from 'lodash-es/isFinite';
import orderBy from 'lodash-es/orderBy';
import { useMemo } from 'react';

import {
  ACCEPTONLY_QUEST_TEMPLATE_NAME,
  COMPLETEONLY_QUEST_TEMPLATE_NAME,
  GAMING_QUEST_TEMPLATE_NAME,
  QUEST_STATUS,
  QuestDetailsType,
  QuestTemplateType,
} from '@/lib/features/quests/types';
import { getDate } from '@/lib/helpers/date';

export function sortQuestsByTag(items?: QuestDetailsType[]) {
  if (!Array.isArray(items)) return {};

  const groupedBySlug = groupBy(
    items,
    (element) => element.tags?.[0]?.slug || 'general',
  );

  return orderBy(Object.entries(groupedBySlug), ([key]) => key).reduce(
    (acc: Record<string, any>, [key, values]) => {
      const label = values[0].tags?.[0]?.label || 'General';
      acc[key] = { label, slug: key, list: values };
      return acc;
    },
    {},
  );
}

export function shouldDisplayCompleteButton(
  status?: QUEST_STATUS,
  template?: string,
): boolean {
  if (!status || !template) return false;

  switch (template) {
    case ACCEPTONLY_QUEST_TEMPLATE_NAME.IMAGE_SUBMISSION:
      return false;
    case ACCEPTONLY_QUEST_TEMPLATE_NAME.TEXT_SUBMISSION:
      return false;
  }

  return status === QUEST_STATUS.INACTIVE || status === QUEST_STATUS.ACTIVE;
}

export function shouldDisplayGamingCompleteButton(
  status?: QUEST_STATUS,
): boolean {
  if (!status) return false;
  return status === QUEST_STATUS.ACTIVE || status === QUEST_STATUS.RESOLVING;
}

const LOCAL_STORAGE_VALUE = {
  QUESTS_REFRESH_TIMER: 'quests-refresh-timer',
};

export const handleButtonCompleteTimer = (
  questId: string,
  setTimer: (val?: Date) => void,
) => {
  const qrt = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VALUE.QUESTS_REFRESH_TIMER) as any,
  );
  if (qrt) {
    const current = qrt.find((item: any) => item.id === questId);
    if (current) {
      let diff = getDate().getTime() - getDate(current.t).getTime();

      // Wait time is already over
      if (diff > 0) {
        const filter = qrt.filter((item: any) => item.id !== questId);
        localStorage.setItem(
          LOCAL_STORAGE_VALUE.QUESTS_REFRESH_TIMER,
          JSON.stringify(filter),
        );

        setTimer(undefined);
      } else {
        setTimer(getDate(current.t));
      }
    }
  }
};

export function isAutoCompleteManual(template?: QuestTemplateType) {
  if (!template) return false;
  if ('autoApprove' in template.args) {
    return Boolean(template.args.autoApprove);
  }
  return false;
}

export function maxAttemptsReached(quest?: QuestDetailsType) {
  return Boolean(
    quest?.attemptLimit &&
      quest.attemptLimit > -1 &&
      quest?.attemptCount === quest?.attemptLimit,
  );
}

export const GET_QUEST_TYPE = {
  ACCEPT_ONLY: 0,
  COMPLETE_ONLY: 1,
  GAMING: 2,
  REFERRALS: 3,
  UNKNOWN: -1,
} as const;

export function getQuestType(
  name?: string,
): (typeof GET_QUEST_TYPE)[keyof typeof GET_QUEST_TYPE] {
  if (!name) return GET_QUEST_TYPE.UNKNOWN;
  if (name === COMPLETEONLY_QUEST_TEMPLATE_NAME.REFERRAL_COUNT)
    return GET_QUEST_TYPE.REFERRALS;
  if (Object.values(ACCEPTONLY_QUEST_TEMPLATE_NAME).some((v) => v === name))
    return GET_QUEST_TYPE.ACCEPT_ONLY;
  if (Object.values(COMPLETEONLY_QUEST_TEMPLATE_NAME).some((v) => v === name))
    return GET_QUEST_TYPE.COMPLETE_ONLY;
  if (Object.values(GAMING_QUEST_TEMPLATE_NAME).some((v) => v === name))
    return GET_QUEST_TYPE.GAMING;

  return GET_QUEST_TYPE.UNKNOWN;
}

export function formatStat(
  stat: string | number,
  target?: string | number,
): string {
  if (target && isValidNumber(stat) && isValidNumber(target)) {
    return `${parseAndRound(stat)}/${parseAndRound(target)}`;
  } else if (isValidNumber(stat)) {
    return parseAndRound(stat);
  }
  return stat.toString();
}

function isValidNumber(value: string | number): boolean {
  return !isNaN(Number(value)) && isFinite(Number(value));
}

function parseAndRound(value: string | number): string {
  return roundToMaxTwoDecimalPlaces(Number(value));
}

function roundToMaxTwoDecimalPlaces(num: number) {
  const roundedNum = Math.round(num * 100) / 100;
  return roundedNum === Math.floor(roundedNum)
    ? roundedNum.toFixed(0)
    : roundedNum.toFixed(2);
}

export function findTagIdInHistory(
  tagId: string,
  historyResponse: QuestDetailsType[],
) {
  let count = 0;
  historyResponse?.forEach((q) => {
    q.tags?.forEach((t) => {
      if (t.tagId === tagId) {
        count++;
      }
    });
  });

  return count;
}

export const useQuestObjective = (quest: QuestDetailsType | undefined) => {
  return useMemo(() => {
    return Object.entries(quest?.template?.args ?? []).reduce(
      (acc, [templateName, value]) => {
        return acc && quest?.metrics?.[templateName] === value;
      },
      false,
    );
  }, [quest?.template.args, quest?.metrics]);
};
