import { Endpoint } from '@/lib/infra/api/constants';

export function getPublicConfig({
  communityId,
  tenant,
  baseUrl,
}: {
  communityId?: string;
  tenant: string;
  baseUrl: string;
}) {
  return fetch(
    `${baseUrl}${Endpoint.GET_PUBLIC_CONFIG}${
      communityId ? `?communityId=${communityId}` : ''
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        'XB-Tenant': tenant,
      },
    },
  ).then((res) => res.json());
}

export function getPrivateConfig({
  sharedSecret,
  baseUrl,
  communityId,
  tenant,
}: {
  sharedSecret: string;
  baseUrl: string;
  tenant: string;
  communityId?: string;
}) {
  return fetch(
    `${baseUrl}${Endpoint.GET_PRIVATE_CONFIG}${
      communityId ? `?communityId=${communityId}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        'XB-Tenant': tenant,
        'X-Shared-Secret': sharedSecret,
      },
    },
  ).then((res) => res.json());
}
