import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import format from 'string-format';

import {
  AuthState,
  ReferralListType,
  USER_AUTH_STATE,
  UserProfileSocialType,
  UserProfileType,
  UserReferralType,
} from '@/lib/features/auth/types';
import { getDisplayName, getUserState } from '@/lib/features/auth/utils';
import { Endpoint } from '@/lib/infra/api/constants';
import { CLIENT_QUERY_KEYS } from '@/lib/infra/react-query/query-client';

export async function getUserProfile(api: AxiosInstance) {
  return api.get<UserProfileType>(Endpoint.GET_PROFILE).then((res) => res.data);
}

export async function getUserSocials(api: AxiosInstance) {
  return api
    .get<{ results: UserProfileSocialType[] }>(Endpoint.SOCIALS)
    .then((res) => res.data.results);
}

export function getUserReferredList(
  api: AxiosInstance,
): Promise<UserReferralType[]> {
  return api
    .get<ReferralListType>(Endpoint.REFERRALS)
    .then((res) => res.data?.referrals);
}

export function getProfile(
  api: AxiosInstance,
  slim?: boolean,
  isAuthenticated?: boolean,
) {
  return async (): Promise<AuthState> => {
    if (isAuthenticated) {
      const profile = await getUserProfile(api);
      const state = getUserState(profile);

      const base = {
        profile,
        state,
        isLoggedIn: state === USER_AUTH_STATE.POST_CLAIM,
        displayName: getDisplayName(
          profile.userId,
          profile.profile?.handle,
          profile.profile?.displayName,
        ),
      };

      if (!slim) {
        const socials = await getUserSocials(api);
        const referredList = await getUserReferredList(api);

        return {
          ...base,
          socials,
          referredList,
        };
      } else {
        return base;
      }
    }

    return {
      isLoggedIn: false,
      state: USER_AUTH_STATE.NOT_REGISTERED,
      displayName: 'Guest',
    };
  };
}

export function useUserByHandle(
  api: AxiosInstance,
  communityId: string,
  userId: string,
) {
  return useQuery({
    queryKey: [CLIENT_QUERY_KEYS.GET_USER_BY_HANDLE, { userId, communityId }],
    queryFn: () => getUserByHandle(api, communityId, userId),
    enabled: !!userId && !!communityId,
  });
}

export async function getUserByHandle(
  api: AxiosInstance,
  communityId: string,
  userId: string,
) {
  return api
    .get<{
      user: UserProfileType & { socials: UserProfileSocialType[] };
      resources: {
        balance: number;
        leaderboardRank: number;
        rankData: {
          name: string;
          target: number;
          nextRank: {
            name: string;
            nextRank: null;
            rankingId: string;
            target: number;
          };
        };
        resourceType: {
          name: string;
          resourceTypeId: string;
          slug: string;
        };
      }[];
      items: {
        balance: number;
        itemId: string;
        item: {
          categorySlug: string;
          description: string;
          equipped: boolean;
          name: string;
          rarity: string;
          slug: string;
        };
      }[];
    }>(format(Endpoint.GET_PROFILE_BY_HANDLE, { userId, communityId }))
    .then((res) => res.data);
}
