export type GameType = {
  gameId: string;
  slug: string;
  name: string;
  description: string;
};

export enum GamesActionTypes {
  GET_ALL_GAMES = 'games/GET_ALL_GAMES',
  GET_ALL_GAMES_PENDING = 'games/GET_ALL_GAMES_PENDING',
  GET_ALL_GAMES_FULFILLED = 'games/GET_ALL_GAMES_FULFILLED',
  GET_ALL_GAMES_REJECTED = 'games/GET_ALL_GAMES_REJECTED',

  GET_GAME_DETAILS = 'games/GET_GAME_DETAILS',
  GET_GAME_DETAILS_PENDING = 'games/GET_GAME_DETAILS_PENDING',
  GET_GAME_DETAILS_FULFILLED = 'games/GET_GAME_DETAILS_FULFILLED',
  GET_GAME_DETAILS_REJECTED = 'games/GET_GAME_DETAILS_REJECTED',
}

export interface GamesInterface {
  details: {
    game?: SingleGameType;
    status: GAMING_STATUS_TYPE;
  };
  data: {
    list: SingleGameType[];
    status: GAMING_STATUS_TYPE;
  };
}

export type SingleGameType = {
  description: string;
  gameId: string;
  items: number;
  name: string;
  slug: string;
  isMember: boolean;
  memberCount: number;
};

export enum GAMING_STATUS_TYPE {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}
