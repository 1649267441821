import { SingleCommunityType } from '@/lib/features/community/types';

export enum AuthActionTypes {
  LOGIN_USER_PASSWORD = 'auth/LOGIN_USER_PASSWORD',
  LOGIN_USER_PASSWORD_FULFILLED = 'auth/LOGIN_USER_PASSWORD_FULFILLED',
  LOGIN_USER_PASSWORD_PENDING = 'auth/LOGIN_USER_PASSWORD_PENDING',
  LOGIN_USER_PASSWORD_REJECTED = 'auth/LOGIN_USER_PASSWORD_REJECTED',

  LOGIN_USER_WEB3 = 'auth/LOGIN_USER_WEB3',
  LOGIN_USER_WEB3_FULFILLED = 'auth/LOGIN_USER_WEB3_FULFILLED',
  LOGIN_USER_WEB3_PENDING = 'auth/LOGIN_USER_WEB3_PENDING',
  LOGIN_USER_WEB3_REJECTED = 'auth/LOGIN_USER_WEB3_REJECTED',

  LOGIN_USER_GOOGLE = 'auth/LOGIN_USER_GOOGLE',
  LOGIN_USER_GOOGLE_FULFILLED = 'auth/LOGIN_USER_GOOGLE_FULFILLED',
  LOGIN_USER_GOOGLE_PENDING = 'auth/LOGIN_USER_GOOGLE_PENDING',
  LOGIN_USER_GOOGLE_REJECTED = 'auth/LOGIN_USER_GOOGLE_REJECTED',

  LOGIN_USER_OAUTH = 'auth/LOGIN_USER_OAUTH',
  LOGIN_USER_OAUTH_FULFILLED = 'auth/LOGIN_USER_OAUTH_FULFILLED',
  LOGIN_USER_OAUTH_PENDING = 'auth/LOGIN_USER_OAUTH_PENDING',
  LOGIN_USER_OAUTH_REJECTED = 'auth/LOGIN_USER_OAUTH_REJECTED',

  USER_CREATE_PROFILE = 'auth/USER_CREATE_PROFILE',
  USER_CREATE_PROFILE_FULFILLED = 'auth/USER_CREATE_PROFILE_FULFILLED',
  USER_CREATE_PROFILE_REJECTED = 'auth/USER_CREATE_PROFILE_REJECTED',

  LOGOUT = 'auth/LOGOUT',
  LOGOUT_FULFILLED = 'auth/LOGOUT_FULFILLED',
  LOGIN_RESTORE = 'auth/LOGIN_RESTORE',

  UPDATE_TOKEN = 'auth/UPDATE_TOKEN',
  UPDATE_TOKEN_FULFILLED = 'auth/UPDATE_TOKEN_FULFILLED',
  UPDATE_TOKEN_REJECTED = 'auth/UPDATE_TOKEN_REJECTED',

  UPDATE_USER_AUTH_STATE = 'auth/UPDATE_USER_AUTH_STATE',

  PICK_GAMES = 'auth/PICK_GAMES',
  PICK_GAMES_FULFILLED = 'auth/PICK_GAMES_FULFILLED',
  PICK_GAMES_REJECTED = 'auth/PICK_GAMES_REJECTED',
}

export enum CredentialType {
  PASSWORD = 'password',
  GOOGLE = 'google',
  TWITTER = 'twitter',
  DISCORD = 'discord',
  WEB3 = 'web3',
}

export interface AuthInterface {
  error?: AUTH_ERROR_TYPE | any;
  type?: CredentialType;
  state: USER_AUTH_STATE;
  status: AUTH_STATUS_TYPE;
}

export type UserSessionType = {
  userId: string;
  sessionToken: string;
  refreshToken: string;
};

export type UserSessionRestoreType = {
  sessionToken: string;
};

export enum AUTH_ERROR_TYPE {
  LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED',
}

export enum AUTH_STATUS_TYPE {
  UNAUTHORIZED = 'UNAUTHORIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export enum WEB3_WALLET_TYPE {
  METAMASK = 'METAMASK',
  COINBASE = 'COINBASE',
  BRAVE = 'BRAVE',
  WALLET_CONNECT = 'WALLET_CONNECT',
  MAGIC_LINK = 'MAGIC_LINK',
}

export enum USER_AUTH_STATE {
  LOADING = 'LOADING',
  NOT_REGISTERED = 'NOT_REGISTERED',
  ONBOARDING = 'ONBOARDING',
  POST_CLAIM = 'POST_CLAIM',
}

export enum UserActionTypes {
  GET_USER_PROFILE = 'user/GET_USER_PROFILE',
  GET_USER_PROFILE_FULFILLED = 'user/GET_USER_PROFILE_FULFILLED',
  GET_USER_PROFILE_REJECTED = 'user/GET_USER_PROFILE_REJECTED',

  UPDATE_USER_PROFILE = 'user/UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_FULFILLED = 'user/UPDATE_USER_PROFILE_FULFILLED',
  UPDATE_USER_PROFILE_REJECTED = 'user/UPDATE_USER_PROFILE_REJECTED',

  GET_USER_COMMUNITIES = 'user/GET_USER_COMMUNITIES',
  GET_USER_COMMUNITIES_FULFILLED = 'user/GET_USER_COMMUNITIES_FULFILLED',
  GET_USER_COMMUNITIES_REJECTED = 'user/GET_USER_COMMUNITIES_REJECTED',

  GET_USER_GAMES = 'user/GET_USER_GAMES',
  GET_USER_GAMES_FULFILLED = 'user/GET_USER_GAMES_FULFILLED',
  GET_USER_GAMES_REJECTED = 'user/GET_USER_GAMES_REJECTED',

  CHANGE_USER_PASSWORD = 'user/CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_FULFILLED = 'user/CHANGE_USER_PASSWORD_FULFILLED',
  CHANGE_USER_PASSWORD_REJECTED = 'user/CHANGE_USER_PASSWORD_REJECTED',

  CHANGE_USER_PRIVACY = 'user/CHANGE_USER_PRIVACY',
  CHANGE_USER_PRIVACY_FULFILLED = 'user/CHANGE_USER_PRIVACY_FULFILLED',
  CHANGE_USER_PRIVACY_REJECTED = 'user/CHANGE_USER_PRIVACY_REJECTED',

  GET_USER_SOCIALS = 'user/GET_USER_SOCIALS',
  GET_USER_SOCIALS_FULFILLED = 'user/GET_USER_SOCIALS_FULFILLED',
  GET_USER_SOCIALS_REJECTED = 'user/GET_USER_SOCIALS_REJECTED',

  GET_USER_REFERED_LIST = 'user/GET_USER_REFERED_LIST',
  GET_USER_REFERED_LIST_FULFILLED = 'user/GET_USER_REFERED_LIST_FULFILLED',
  GET_USER_REFERED_LIST_PENDING = 'user/GET_USER_REFERED_LIST_PENDING',
  GET_USER_REFERED_LIST_REJECTED = 'user/GET_USER_REFERED_LIST_REJECTED',

  UPDATE_USER_SOCIALS = 'user/UPDATE_USER_SOCIALS',
  UPDATE_USER_SOCIALS_FULFILLED = 'user/UPDATE_USER_SOCIALS_FULFILLED',
  UPDATE_USER_SOCIALS_REJECTED = 'user/UPDATE_USER_SOCIALS_REJECTED',

  REMOVE_USER_SOCIAL = 'user/REMOVE_USER_SOCIAL',
  REMOVE_USER_SOCIAL_FULFILLED = 'user/REMOVE_USER_SOCIAL_FULFILLED',
  REMOVE_USER_SOCIAL_REJECTED = 'user/REMOVE_USER_SOCIAL_REJECTED',

  DELETE_USER_ACCOUNT = 'user/DELETE_USER_ACCOUNT',
  DELETE_USER_ACCOUNT_FULFILLED = 'user/DELETE_USER_ACCOUNT_FULFILLED',
  DELETE_USER_ACCOUNT_REJECTED = 'user/DELETE_USER_ACCOUNT_REJECTED',

  RESET_USER = 'user/RESET_USER',
}

export interface UserInterface {
  data?: UserProfileType;
  socials?: UserProfileSocialType[];
  referred?: {
    list: UserProfileDetailsType[];
    status: USER_STATUS_TYPE;
  };
  // games?: UserGameType[];
  communities: {
    list?: SingleCommunityType[];
    status: USER_STATUS_TYPE;
  };
  error?: string;
}

export type UserProfileDetailsType = {
  userId: string;
  handle?: string;
  displayName?: string;
  email?: string;
};

export type UserReferralType = {
  referralId: string;
  createdAt: Date;
  referee: UserProfileDetailsType;
};

export type ReferralListType = {
  referrals: UserReferralType[];
};

export type UserProfileType = {
  userId: string;
  profile: UserProfileDetailsType;
  createdAt: Date;
  creationIndex?: number;
  resources?: {
    exp?: {
      balance: number;
      resourceType: {
        global: boolean;
        name: string;
        resourceTypeId: string;
        slug: string;
      };
    };
  };
  referrals: {
    count: number;
  };
  roles?: USER_ROLE_TYPE[];
};

export type UserCommunityType = {
  id: number;
  image: string;
  title: string;
};

export type UserGameType = {
  id: number;
  image: string;
  title: string;
};

export type UserProfileSocialType = {
  accountType: USER_SOCIAL_TYPE;
  socialId: string;
  subjectId: string;
  handle?: string;
  server?: string;
  createdAt?: Date;
  expiryDate?: Date;
  extraArgs?: Record<string, string>;
};

export enum USER_PROFILE_TYPE {
  STANDARD = 'STANDARD',
  PRO = 'PRO',
}

export enum USER_ROLE_TYPE {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
  PRE_LAUNCH_WHITELIST = 'PRE_LAUNCH_WHITELIST',
}

export enum USER_SOCIAL_TYPE {
  TWITTER = 'TWITTER',
  DISCORD = 'DISCORD',
  TWITCH = 'TWITCH',
  STEAM = 'STEAM',
  RIOT = 'RIOT',
  YOUTUBE = 'YOUTUBE',
  BATTLENET = 'BATTLENET',
  EPIC = 'EPIC',
  FACEIT = 'FACEIT',
  APEIRON = 'APEIRON',
}

export enum USER_STATUS_TYPE {
  UNAUTHORIZED = 'UNAUTHORIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export type CookieKeys = {
  session: string;
  refresh: string;
};

export type WowCharacter = {
  character: string;
  realm: string;
  realmSlug: string;
};

export type AuthState = {
  isLoggedIn: boolean;
  state: USER_AUTH_STATE;
  displayName: string;
  profile?: UserProfileType;
  socials?: UserProfileSocialType[];
  referredList?: UserReferralType[];
};
