import { QueryClient } from '@tanstack/react-query';

import { LEADERBOARD_STATUS_TYPE, RankType } from './community/types';

export enum LeaderboardActionTypes {
  GET_LEADERBOARD = 'leaderboard/GET_LEADERBOARD',
  GET_LEADERBOARD_FULFILLED = 'leaderboard/GET_LEADERBOARD_FULFILLED',
  GET_LEADERBOARD_PENDING = 'leaderboard/GET_LEADERBOARD_PENDING',
  GET_LEADERBOARD_REJECTED = 'leaderboard/GET_LEADERBOARD_REJECTED',

  GET_EVENTS_LEADERBOARD = 'leaderboard/GET_EVENTS_LEADERBOARD',
  GET_EVENTS_LEADERBOARD_FULFILLED = 'leaderboard/GET_EVENTS_LEADERBOARD_FULFILLED',
  GET_EVENTS_LEADERBOARD_PENDING = 'leaderboard/GET_EVENTS_LEADERBOARD_PENDING',
  GET_EVENTS_LEADERBOARD_REJECTED = 'leaderboard/GET_EVENTS_LEADERBOARD_REJECTED',

  GET_USERRANK = 'leaderboard/GET_USERRANK',
  GET_USERRANK_FULFILLED = 'leaderboard/GET_USERRANK_FULFILLED',
  GET_USERRANK_PENDING = 'leaderboard/GET_USERRANK_PENDING',
  GET_USERRANK_REJECTED = 'leaderboard/GET_USERRANK_REJECTED',
}

export interface LeaderboardInterface {
  pagination: {
    page: number;
    limit: number;
  };
}

export type LeaderboardUserRankType = {
  data?: LeaderboardListItemType;
  status: LEADERBOARD_STATUS_TYPE;
};

export type LeaderboardListType = {
  data: LeaderboardListItemType[];
  status: LEADERBOARD_STATUS_TYPE;
};

export type LeaderboardListItemType = {
  user: {
    userId: string;
    handle?: string;
    displayName?: string;
  };
  rank?: number;
  balance: number;
};

export type RankingItemType = {
  balance: number;
  current: RankType | null;
  next: RankType | null;
  slug: string;
  resourceTypeId: string;
  resourceImageUrl?: string;
  leaderboardRank?: number;
};

export type ParsableContent =
  | {
      type?: SupportedTags;
      text?: string;
      children: ParsableContent[];
      italic?: boolean;
      bold?: boolean;
      code?: boolean;
      strikethrough?: boolean;
    }
  | {
      type: 'link';
      url: string;
      newTab: boolean;
      children: ParsableContent[];
      italic?: boolean;
      bold?: boolean;
      code?: boolean;
      strikethrough?: boolean;
    };

export type SupportedTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'li'
  | 'a'
  | 'ul'
  | 'ol'
  | 'div'
  | 'blockquote';

export type TagProps = {
  label: string;
  slug: string;
  active?: boolean;
  icon?: any;
  suffix?: any;
  disabled?: boolean;
  top?: string;
  onClick?: (val: string) => void;
};

export type CommonQueryClientOptions = Partial<{
  queryClient: QueryClient;
}>;

export const SocialTypeIndex = {
  1: 'TWITTER',
  2: 'DISCORD',
  3: 'TWITCH',
  4: 'STEAM',
  5: 'FACEIT',
  6: 'RIOT',
  7: 'YOUTUBE',
  8: 'BUSHI',
  9: 'BATTLENET',
  10: 'EPIC',
  11: 'APEIRON',
} as const;

export type SocialType = (typeof SocialTypeIndex)[keyof typeof SocialTypeIndex];
