import axios, { AxiosInstance } from 'axios';
import format from 'string-format';

import { getCommunity } from '@/lib/features/community/api/communities';
import { SingleEventType } from '@/lib/features/quest-event/types';
import {
  QUEST_STATUS,
  QuestDetailsType,
  QuestsFiltersType,
} from '@/lib/features/quests/types';
import { urlfy } from '@/lib/helpers/urlfy';
import { Endpoint } from '@/lib/infra/api/constants';

export function getAllQuests(api: AxiosInstance, filters?: QuestsFiltersType) {
  return api
    .get<QuestDetailsType[]>(Endpoint.GET_QUESTS + urlfy(filters))
    .then((res) => res.data);
}

export function getQuestFilters(api: AxiosInstance, tags?: string[]) {
  return api
    .get<{ tags?: string[] }>(
      Endpoint.GET_QUESTS_FILTERS + urlfy({ tags: tags ?? [] }),
    )
    .then((res) => res.data);
}

export function getQuestDetails(api: AxiosInstance, id: string) {
  return api
    .get<QuestDetailsType>(format(Endpoint.GET_QUEST, { id }))
    .then((res) => res.data);
}

export function getQuestResults(api: AxiosInstance, id: string) {
  return api
    .get(format(Endpoint.GET_QUEST_RESULTS, { id }))
    .then((res) => res.data);
}

export function postQuestLoadContext(
  api: AxiosInstance,
  id: string,
  data?: Record<string, string>,
) {
  return api
    .post(format(Endpoint.LOAD_QUEST_CONTEXT, { id }), data)
    .then((res) => res.data);
}

export function getQuestEvent(api: AxiosInstance, id?: string) {
  return api
    .get<typeof id extends undefined ? SingleEventType[] : SingleEventType>(
      format(Endpoint.GET_EVENT_DETAILS, { id }),
    )
    .then((res) => res.data);
}

export function postRefreshQuest(api: AxiosInstance, id: string) {
  return api
    .post(format(Endpoint.REFRESH_QUEST, { id }))
    .then((res) => res.data);
}

export function postAcceptQuest(api: AxiosInstance, id: string, payload?: any) {
  return api
    .post(format(Endpoint.ACCEPT_QUEST, { id }), payload)
    .then((res) => res.data);
}

export function postCompleteQuest(
  api: AxiosInstance,
  id: string,
  payload?: any,
) {
  return api
    .post(format(Endpoint.COMPLETE_QUEST, { id }), payload)
    .then((res) => res.data);
}

export async function getQuestEventById(
  api: AxiosInstance,
  id?: string,
  filter?: string,
) {
  if (!id) throw new Error('No id provided');
  const event = await getQuestEvent(api, id);
  const community = await getCommunity(api, event.containerId);
  const publishedQuests = event.quests?.filter((q) => q.published) ?? [];

  // TODO: Now necessary to call quest-details because the quests does not contain template field which is
  // crucial for rendering/detecting quest type/icon etc.
  const questDetails = await Promise.all(
    publishedQuests.map((q) => getQuestDetails(api, q.questId)),
  );
  const filteredResult = questDetails.filter((quest) => {
    return filter && filter === 'completed'
      ? quest.status === QUEST_STATUS.COMPLETED
      : quest.status !== QUEST_STATUS.COMPLETED;
  });

  return {
    ...event,
    quests: filteredResult,
    community,
  };
}

export function byOnlyNeededTags(tag: string) {
  return !['phase1', 'phase2'].includes(tag);
}

export async function prepareImageSubmissionQuest(
  api: AxiosInstance,
  questId: string,
  files: File[],
) {
  const file = files[0];

  // Load context
  const contextRes = await postQuestLoadContext(api, questId, {
    fileType: file.type,
  });

  const formFields = {
    ...JSON.parse(contextRes.context.fields),
    file,
    'Content-Type': file.type,
  };

  const formData = new FormData();
  for (const field in formFields) {
    formData.append(field, formFields[field]);
  }

  return await postImageSubmissionFormData(contextRes.context.url, formData);
}

export function postImageSubmissionFormData(url: string, formData: FormData) {
  return axios.post(url, formData);
}
