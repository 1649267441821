import axios, { CreateAxiosDefaults } from 'axios';

import { isUnauthorizedCall } from './helpers';

type CreateApiTokenConfig = {
  session: string;
  refreshToken: string;
};

type ApiConfig = {
  axios?: CreateAxiosDefaults;
  tokens?: CreateApiTokenConfig;
};

export function getApi(config: ApiConfig) {
  const { axios: axiosConfig, tokens } = config;

  const _api = axios.create(axiosConfig);

  _api.interceptors.request.use((config) => {
    if (config.url && isUnauthorizedCall(config.url)) {
      delete config.headers.Authorization;
    } else if (tokens) {
      config.headers.Authorization = `Bearer ${tokens.session}`;
    }

    return config;
  });

  return _api;
}
