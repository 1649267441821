'use client';
import { integralCF, lexend } from '@/assets/fonts/fonts';
import { ErrorPage } from '@/modules/shared/components/Template/ErrorPage/ErrorPage';

export default function NotFound() {
  return (
    <div className={` ${lexend.variable} ${integralCF.variable}`}>
      <ErrorPage code={404} />
    </div>
  );
}
