import { AxiosInstance } from 'axios';
import format from 'string-format';

import {
  CommunityQuestFilter,
  QuestDetailsType,
  QuestFilterOptions,
  QuestsFiltersType,
} from '@/lib/features/quests/types';
import { urlfy } from '@/lib/helpers/urlfy';
import { Endpoint } from '@/lib/infra/api/constants';
import { toQueryString } from '@/lib/infra/api/helpers';

export function getCommunityQuests(
  api: AxiosInstance,
  id: string,
  filters?: QuestsFiltersType,
) {
  return api
    .get<QuestDetailsType[]>(
      format(Endpoint.GET_COMMUNITY_QUESTS, { id }) + urlfy(filters),
    )
    .then((res) => res.data);
}

export function getCommunityQuestFilters(
  api: AxiosInstance,
  id: string,
  options?: Partial<QuestFilterOptions>,
) {
  const queryString = toQueryString(options);
  return api
    .get<{
      offset: number;
      total: number;
      tags: CommunityQuestFilter[];
    }>(
      `${format(Endpoint.GET_COMMUNITY_QUEST_TAGS, { id })}${
        queryString ? `?${queryString}` : ''
      }`,
    )
    .then((res) => res.data);
}

export function getQuestHistory(
  api: AxiosInstance,
  filters?: QuestsFiltersType,
) {
  return api
    .get<QuestDetailsType[]>(Endpoint.GET_QUESTS_HISTORY + urlfy(filters))
    .then((res) => res.data);
}
