import {
  InventorySlotItemType,
  ItemRarity,
} from '@/lib/features/inventory/types';

export function isLocked(item: InventorySlotItemType) {
  if (!item.balance && item.accessType !== 'public') {
    return true;
  }
  return item.balance === 0 && item.accessType === 'private';
}

export function isStringItemRarity(value: string): value is ItemRarity {
  return Object.values(ItemRarity).includes(value as ItemRarity);
}
