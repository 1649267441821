import { SocialType, SocialTypeIndex } from '../../types';

/**
 * Get the required social from the code returned by API
 * note: This might change in the future, when BE sends the actual social type instead of the code.
 * @param code SocialType code of the required social.
 */
export function getRequiredSocial(code?: number): SocialType | undefined {
  if (code && code in SocialTypeIndex)
    return SocialTypeIndex[code as keyof typeof SocialTypeIndex];
}
