import { AxiosInstance } from 'axios';

import { Endpoint } from '@/lib';

export type Payload = Partial<{
  socialId: string;
  handle: string;
  server: string;
  expiryDate: string;
  userToken: string;
  refreshToken: string;
  extraArgs: Record<string, string>;
}>;
export function updateSocials(api: AxiosInstance, payload: Payload) {
  return api.post(Endpoint.UPDATE_SOCIALS, payload).then((res) => res.data);
}
