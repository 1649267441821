export function getRelativeTimeDiff(dateObj: Date): [number, string] {
  const now = Date.now();
  const timeDiff = dateObj.getTime() - now;

  const totalSeconds = Math.trunc(timeDiff / 1000);
  switch (true) {
    case Math.abs(totalSeconds) < 60:
      return [totalSeconds, 'second'];
    case Math.abs(totalSeconds) < 3600:
      return [Math.trunc(totalSeconds / 60), 'minute'];
    case Math.abs(totalSeconds) < 86400:
      return [Math.trunc(totalSeconds / 3600), 'hour'];
    case Math.abs(totalSeconds) < 2592000:
      return [Math.trunc(totalSeconds / 86400), 'day'];
    case Math.abs(totalSeconds) < 31536000:
      return [Math.trunc(totalSeconds / 2592000), 'month'];
    default:
      return [Math.trunc(totalSeconds / 31536000), 'year'];
  }
}

export function getDate(
  value?:
    | string
    | {
        iso: string;
        millis: number;
      }
    | Date,
) {
  if (!value) return new Date();
  if (value instanceof Date) return value;
  if (typeof value === 'string') {
    return new Date(value);
  }

  if (value.iso) {
    return new Date(value.iso);
  }

  return new Date();
}
