import { AxiosInstance } from 'axios';
import format from 'string-format';

import { GameType, SingleGameType } from '@/lib/features/gaming/types';
import { Endpoint } from '@/lib/infra/api/constants';

export function getAllGames(api: AxiosInstance) {
  return api
    .get(Endpoint.GET_ALL_GAMES)
    .then((res) => res.data.results as SingleGameType[]);
}

export function getGameDetails(api: AxiosInstance, id: string) {
  return api
    .get<{
      result: GameType;
    }>(format(Endpoint.GET_GAME_DETAILS, { id }))
    .then((res) => res.data.result);
}
