import { AxiosInstance } from 'axios';

import { UserSessionType } from '@/lib/features/auth/types';
import { Endpoint } from '@/lib/infra/api/constants';

export function loginUserOauth(
  api: AxiosInstance,
  loginUrl: string,
  token: string,
  referrerHandle?: string,
) {
  return api
    .post<UserSessionType>(Endpoint.LOGIN_OAUTH + loginUrl, {
      token,
      referrerHandle,
    })
    .then((res) => res.data);
}

export function zkloginUserOauth(
  api: AxiosInstance,
  loginUrl: string,
  token: string,
  accessToken?: string,
  referrerHandle?: string,
) {
  return api
    .post<UserSessionType>(Endpoint.LOGIN_OAUTH + loginUrl, {
      token,
      accessToken,
      referrerHandle,
    })
    .then((res) => res.data);
}

export function refreshAuthToken(api: AxiosInstance, refreshToken: string) {
  return api
    .post<{ sessionToken: string }>(Endpoint.REFRESH_TOKEN, {
      refreshToken,
    })
    .then((res) => res.data);
}
