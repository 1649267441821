export const urlfy = (data?: Record<string, string | string[]>) => {
  if (!data) return '';

  const params = new URLSearchParams();
  for (const key of Object.keys(data)) {
    const value: string | string[] = data[key];
    if (!value || (Array.isArray(value) && value.length === 0)) continue;

    if (Array.isArray(value)) {
      for (const entry of value) {
        params.append(`${key}[]`, entry);
      }
    } else {
      params.set(key, value);
    }
  }

  if (params.size > 0) {
    return `?${params.toString()}`;
  } else {
    return '';
  }
};
