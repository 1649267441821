import get from 'lodash-es/get';

const formatRec = (fmt: string, args: Record<string, any>) =>
  fmt.replace(/{(.+?)}/g, (match: string) => {
    const formatMatch = match.replace('{', '').replace('}', '');
    return get(args, formatMatch, '');
  });

const formatArr = (fmt: string, args: any[]) =>
  fmt.replace(/{(\d*)}/g, (match: string, index: number) => {
    const idx = match ? Number.parseInt(match) : index;
    if (idx < args.length) return args[idx];
    else return match;
  });

/**
 * Usage
 * format('Hello {0}', 'World') => 'Hello World'
 * format('Hello {name}', { name: 'World' }) => 'Hello World'
 */
export const formatString = (
  fmt: string,
  args: Record<string, any> | any,
  ...rest: any[]
): string =>
  typeof args === 'object'
    ? formatRec(fmt ?? '', args)
    : formatArr(fmt ?? '', [args, ...rest]);

export function shortenAddress(address: string): string {
  return address ? `${address.slice(0, 5)}...${address.slice(-5)}` : '';
}

export function hiddenString(s: string, maxLength: number = s.length): string {
  return '•'.repeat(Math.min(s.length, maxLength));
}

const IdPattern = /^urn:xb-[^:]+?:(.+)$/;

export function shortenId(id: string) {
  if (!id) return null;

  const match = id.match(IdPattern);
  if (match !== null) {
    return match[1];
  } else {
    return id;
  }
}
