import { AxiosInstance } from 'axios';

import { Endpoint } from '@/lib/infra/api/constants';

import { UserSessionType } from '../types';

export function loginWithGoogle(
  api: AxiosInstance,
  {
    credential,
    referrerHandle,
  }: {
    credential: string;
    referrerHandle?: string;
  },
) {
  return api
    .post<UserSessionType>(Endpoint.LOGIN_GOOGLE, {
      credential,
      referrerHandle,
    })
    .then((res) => res.data);
}
