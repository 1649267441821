import { AxiosInstance } from 'axios';
import format from 'string-format';

import { LeaderboardListItemType } from '@/lib/features/community/types';
import { SingleEventType } from '@/lib/features/quest-event/types';
import { getDate } from '@/lib/helpers/date';
import { Endpoint } from '@/lib/infra/api/constants';

export async function getFeaturedEvents(api: AxiosInstance) {
  return await api
    .get<{
      events: SingleEventType[];
      total: number;
      offset: number;
    }>(Endpoint.GET_EVENTS + '?page=1&limit=10&filter[featured]=true')
    .then((res) => res.data);
}

export async function getEvents(api: AxiosInstance) {
  return await api
    .get<{
      events: SingleEventType[];
      total: number;
      offset: number;
    }>(Endpoint.GET_EVENTS)
    .then((res) => res.data);
}

export async function getCommunityEvents(api: AxiosInstance, id: string) {
  return api
    .get<{
      events: SingleEventType[];
      total: number;
      offset: number;
    }>(Endpoint.GET_EVENTS + `?filter[containerId]=${id}`)
    .then((res) => res.data);
}

export async function getLiveAndUpcomingEvents(api: AxiosInstance) {
  return api
    .get<{
      events: SingleEventType[];
      total: number;
      offset: number;
    }>(Endpoint.GET_EVENTS + `?filter[status]=PUBLISHED`)
    .then((res) => res.data)
    .then((data) => {
      const filtered = data.events.filter((event) => {
        const { registrationStartDate, registrationEndDate } = event;
        const start = getDate(registrationStartDate).getTime();
        const end = getDate(registrationEndDate).getTime();
        const today = Date.now();

        return start < today && end > today;
      });

      // filter only live events with registration open
      return {
        total: filtered.length,
        events: filtered,
        offset: data.offset,
      };
    });
}

export async function postParticipateEvent(
  api: AxiosInstance,
  eventId: string,
) {
  return api
    .post(format(Endpoint.PARTICIPATE_EVENT, { eventId }))
    .then((res) => res.data);
}

export async function putLeaveEvent(api: AxiosInstance, eventId: string) {
  return api
    .put(format(Endpoint.LEAVE_EVENT, { eventId }))
    .then((res) => res.data);
}

export async function getEventParticipant(api: AxiosInstance, eventId: string) {
  return api
    .get(format(Endpoint.GET_EVENT_PARTICIPANT, { id: eventId }))
    .then((res) => res.data);
}

export async function getEventUserRank(api: AxiosInstance, eventId: string) {
  return api
    .get(format(Endpoint.GET_EVENT_USER_RANK, { id: eventId }))
    .then((res) => res.data);
}

export async function getQuestEventLeaderBoard(
  api: AxiosInstance,
  eventId: string,
  page: number,
  limit: number,
) {
  return api
    .get<{
      leaderboard: LeaderboardListItemType[];
      offset: number;
      total: number;
    }>(
      format(Endpoint.GET_QUEST_EVENT_LEADERBOARD, { id: eventId }) +
        `?page=${page}&limit=${limit}`,
    )
    .then((res) => res.data);
}

export function getOpenEvents(api: AxiosInstance) {
  return api
    .get<{ events: SingleEventType[]; total: number; offset: number }>(
      `${Endpoint.GET_EVENTS}?filter[status][]=PUBLISHED&sort[column]=startDate&sort[direction]=DESC&registrationOpen=true`,
    )
    .then((res) => res.data);
}

export function getOngoingEvents(api: AxiosInstance) {
  return api
    .get<{ events: SingleEventType[]; total: number; offset: number }>(
      `${Endpoint.GET_EVENTS}?filter[status][]=PUBLISHED&sort[column]=startDate&sort[direction]=DESC&participating=true&registrationOpen=false`,
    )
    .then((res) => res.data);
}
