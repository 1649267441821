import { AxiosInstance } from 'axios';

import { Endpoint, WowCharacter } from '@/lib';

export function getExtraWowCharacters(
  api: AxiosInstance,
): Promise<WowCharacter[]> {
  return api
    .get<{
      results: WowCharacter[];
    }>(Endpoint.EXTRA_WOW_CHARACTERS)
    .then((res) => res.data?.results ?? []);
}
