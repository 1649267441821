import { Endpoint } from './constants';

export function isUnauthorizedCall(url: string) {
  const unauthorizedCalls = [
    Endpoint.REFRESH_TOKEN,
    Endpoint.LOGIN_GOOGLE,
    Endpoint.LOGIN_OAUTH,
  ];

  return unauthorizedCalls.some((call) => url.endsWith(call));
}

export function toQueryString(params?: Record<string, any>): string {
  if (!params) {
    return '';
  }

  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value.toString());
  });

  return searchParams.toString();
}
