import { match } from 'ts-pattern';

import { FilterType, SingleCommunityType } from './types';

export function filters(filterType: FilterType) {
  return match(filterType)
    .with('All', () => (a: SingleCommunityType) => a.name)
    .with('New', () => (a: SingleCommunityType) => a.content?.createdAt)
    .with('Top', () => (a: SingleCommunityType) => a.memberCount)
    .exhaustive();
}
