import { SingleCommunityType } from '@/lib/features/community/types';
import {
  ParticipantSyncStatusType,
  QuestDetailsType,
} from '@/lib/features/quests/types';
import { ParsableContent } from '@/lib/features/types';

export enum EventsActionTypes {
  GET_EVENTS_LIST = 'events/GET_EVENTS_LIST',
  GET_EVENTS_LIST_FULFILLED = 'events/GET_EVENTS_LIST_FULFILLED',
  GET_EVENTS_LIST_PENDING = 'events/GET_EVENTS_LIST_PENDING',
  GET_EVENTS_LIST_REJECTED = 'events/GET_EVENTS_LIST_REJECTED',

  GET_EVENT_DETAILS = 'events/GET_EVENT_DETAILS',
  GET_EVENT_DETAILS_FULFILLED = 'events/GET_EVENT_DETAILS_FULFILLED',
  GET_EVENT_DETAILS_PENDING = 'events/GET_EVENT_DETAILS_PENDING',
  GET_EVENT_DETAILS_REJECTED = 'events/GET_EVENT_DETAILS_REJECTED',

  PARTICIPATE_EVENT = 'events/PARTICIPATE_EVENT',
  PARTICIPATE_EVENT_FULFILLED = 'events/PARTICIPATE_EVENT_FULFILLED',
  PARTICIPATE_EVENT_PENDING = 'events/PARTICIPATE_EVENT_PENDING',
  PARTICIPATE_EVENT_REJECTED = 'events/PARTICIPATE_EVENT_REJECTED',
}

export interface EventsInterface {
  data: {
    events?: SingleEventType[];
    offset?: number;
    total?: number;
    status: EVENTS_STATUS_TYPE;
  };
  details: {
    event?: SingleEventType;
    status: EVENTS_STATUS_TYPE;
  };
}

export enum EVENTS_STATUS_TYPE {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export type ResolutionValueType = {
  min: number;
  max: number;
};

export enum QUEST_EVENT_TYPE {
  BASIC = 1,
  SCORE = 2,
}

export type SingleEventType = {
  containerId: string;
  createdAt: Date;
  createdBy: string;
  type: QUEST_EVENT_TYPE;
  description: string | ParsableContent[];
  endDate: Date;
  eventId: string;
  maxParticipants: number;
  registrationEndDate: Date;
  registrationStartDate: Date;
  startDate: Date;
  title: string;
  totalParticipants: number;
  updatedAt: Date;
  updatedBy: string;
  rewards?: EventRewardType[];
  status: QUEST_EVENT_STATUS;
  slug: string;
  participation?: {
    isParticipating: boolean;
    status: string;
    syncStatus: ParticipantSyncStatusType;
  };
  quests?: QuestDetailsType[];
  images?: {
    coverImageUrl: string;
  };
};

export type EventWithCommunityAndQuests = SingleEventType & {
  community: SingleCommunityType;
  quests: QuestDetailsType[];
};

export type EventRewardType = {
  createdAt: Date;
  createdBy: string;
  eventId: string;
  eventRewardId: string;
  name: string;
  resolutionType: string;
  resolutionValueRange: ResolutionValueType;
  reward: Record<string, string>;
  rewardArgs: Record<string, string>;
  rewardId: string;
  updatedAt: Date;
  updatedBy: string;
};

export enum QUEST_EVENT_STATUS {
  DRAFT = 1,
  PUBLISHED = 2,
  RESOLVING = 3,
  COMPLETED = 4,
  CANCELLED = 5,
}

export enum QuestEventParticipantStatus {
  REGISTERED = 1,
  UNREGISTERED = 2,
  DROPPED = 3,
  CANCELLED = 4,
}

export enum QuestEventRewardResolutionType {
  ABSOLUTE = 1,
  RELATIVE = 2,
}
