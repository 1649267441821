import { AxiosInstance } from 'axios';
import Cookies from 'universal-cookie';

import {
  CookieKeys,
  USER_AUTH_STATE,
  USER_ROLE_TYPE,
  USER_SOCIAL_TYPE,
  UserProfileSocialType,
  UserProfileType,
} from '@/lib/features/auth/types';

export function getUserState(user?: UserProfileType): USER_AUTH_STATE {
  const isAllowed = isWhitelisted(user);
  const handle = user?.profile?.handle ?? '';

  if (!isAllowed && !handle.length) return USER_AUTH_STATE.ONBOARDING;
  if (handle.length) return USER_AUTH_STATE.POST_CLAIM;

  return USER_AUTH_STATE.NOT_REGISTERED;
}

export function isWhitelisted(user?: UserProfileType): boolean {
  const whitelisted = user?.roles?.find(
    (item) => item === USER_ROLE_TYPE.PRE_LAUNCH_WHITELIST,
  );

  return !!whitelisted;
}

export function setCredentials({
  api,
  refreshToken,
  sessionToken,
  cookieKeys,
}: {
  api: AxiosInstance;
  refreshToken: string;
  sessionToken: string;
  cookieKeys: CookieKeys;
}) {
  const cookies = new Cookies();
  cookies.set(cookieKeys.refresh, refreshToken, {
    sameSite: 'lax',
    secure: true,
    path: '/',
    maxAge: 604800,
  });

  cookies.set(cookieKeys.session, sessionToken, {
    sameSite: 'lax',
    secure: true,
    path: '/',
    maxAge: 604800,
  });

  api.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`;
}

export function getUserSocialByType(
  socials: UserProfileSocialType[] | undefined,
  type: USER_SOCIAL_TYPE,
): UserProfileSocialType | undefined {
  return socials?.find((item) => item.accountType === type);
}

export function getDisplayName(
  id: string,
  handle?: string,
  displayName?: string,
): string {
  if (displayName?.length) return displayName;
  if (handle?.length) return handle;

  return shortenUserID(id);
}

export function shortenUserID(id: string): string {
  return id.replace('urn:xb-user:', '');
}
