import { QUEST_REWARD_NAMES } from '../../quests/types';
import { SingleEventType } from '../types';

export function getEventPrimaryResource(event: SingleEventType) {
  const reward = event?.rewards?.find(
    (r) => r.reward.name === QUEST_REWARD_NAMES.CREDIT_RESOURCE,
  );
  if (!reward) return;
  return reward.rewardArgs.resourceTypeId;
}
