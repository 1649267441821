import { AxiosInstance } from 'axios';

import { Endpoint } from '@/lib/infra/api/constants';

export function updateUserProfile(
  api: AxiosInstance,
  handle?: string,
  email?: string,
  privacy?: boolean,
) {
  return api
    .post(Endpoint.EDIT_PROFILE, {
      handle,
      email,
      privacy,
    })
    .then((res) => res.data);
}
