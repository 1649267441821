const events = new Map();
function subscribe(
  eventName: string,
  listener: EventListenerOrEventListenerObject,
): void {
  events.set(eventName, listener);
}

function unsubscribe(eventName: string): void {
  events.delete(eventName);
}

function publish(eventName: string, data?: any): void {
  if (!events.has(eventName)) return;
  const listener = events.get(eventName);
  listener(data);
  events.delete(eventName);
}

function clearEventQueue() {
  events.clear();
}

export { publish, subscribe, unsubscribe, clearEventQueue };
